<template>
  <div
    v-if="isOpen"
    class="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-[99999]"
    @click.self="emit('close')"
  >
    <div class="relative bg-white rounded-lg shadow-xl text-left w-full max-w-md overflow-x-clip mx-2">
      <button
        class="absolute top-2 mt-2 mr-2 text-gray-700 hover:text-gray-900 ltr:right-2 ltr:mr-2 rtl:ml-2 rtl:left-2"
        @click="emit('close')"
      >
        <Icon name="tabler:x" class="w-6 h-6" />
      </button>
      <div value="mb-4">
        <h2 class="text-2xl px-6 pt-4 font-bold ltr:text-left rtl:text-right">
          {{ t('common.site_preferences') }}
        </h2>
      </div>

      <div class="px-6 pt-6">
        <span class="flex font-bold text-sm rtl:text-right">{{ t('common.country') }}</span>
        <MazSelect
          :options="[
            { label: t('forms.country.ae'), value: 'AE' },
            { label: t('forms.country.qa'), value: 'QA' },
            { label: t('forms.country.sa'), value: 'SA' },
            { label: t('forms.country.om'), value: 'OM' },
            { label: t('forms.country.bh'), value: 'BH' },
            { label: t('forms.country.kw'), value: 'KW' },
          ]"
          class="text-sm w-full"
          v-model="selectedCountry"
        />
      </div>

      <div class="p-6">
        <span class="flex font-bold text-sm rtl:text-right">{{ t('common.currency') }}</span>
        <MazSelect
          :options="
            currencies.map((currency) => ({
              value: currency.symbol,
              label: t('common.' + currency.symbol) + ' - ' + t(currency.country),
            }))
          "
          class="text-sm w-full"
          v-model="selectedCurrency"
        />
        <button
          class="w-full text-center py-[16px] mt-4 bg-primary-1000 text-white rounded-md font-medium hover:bg-primary-800"
          @click.stop="handleSave"
        >
          {{ t('common.save') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import MazSelect from 'maz-ui/components/MazSelect';
import { currencies } from '~/models/currencies';

defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
});

const { t, locale } = useI18n();
const switchLocale = useSwitchLocalePath();
const emit = defineEmits(['close']);

const settingsStore = useSettingsStore();
const { userCurrency, userCountry } = storeToRefs(settingsStore);

const selectedCurrency = ref<string>(userCurrency.value || 'AED');
const selectedCountry = ref<string>(userCountry.value.countryCode || 'AE');

const handleSave = () => {
  settingsStore.setCurrency(selectedCurrency.value);
  settingsStore.setUserCountry({
    countryCode: selectedCountry.value,
    country: selectedCountry.value.toLowerCase(),
  });

  const localeValue = locale.value.split('-')[0];

  if (selectedCountry.value === 'AE') {
    navigateTo(switchLocale(localeValue));
  } else {
    navigateTo(switchLocale(localeValue + '-' + selectedCountry.value.toLowerCase()));
  }

  emit('close');
};

const countryCurrencyMap = {
  AE: 'AED',
  QA: 'QAR',
  SA: 'SAR',
  OM: 'OMR',
  BH: 'BHD',
  KW: 'KWD',
};

watch(selectedCountry, (newCountry) => {
  const newCurrency = countryCurrencyMap[newCountry] || 'AED'; // Default to AED if no match found
  selectedCurrency.value = newCurrency;
});

onUnmounted(() => {
  emit('close');
});
</script>

<style scoped></style>
